<template>
  <v-container
    class="products-list-page"
    fluid>
    <BrandTabSelect v-model="brandSelect" />
    <v-row class="products-row">
      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <v-select
              v-model="selectedDraft"
              :items="sortingDraft"
              item-text="name"
              return-object
              color="secondary"
              dense
              outlined
              hide-details />
          </v-col>
          <v-col
            v-if="selectedDraft.id"
            cols="auto">
            <v-btn
              color="error"
              :disabled="disabledSaveBtn"
              @click="confirmDeleteDialog()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              color="secondary"
              :disabled="disabledSaveBtn"
              @click="showDraft()">
              Save Draft
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="success"
              :disabled="disabledSaveBtn"
              @click="save()">
              Publish
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <h3>แสดงบนเว็บ</h3>
        <div class="products-onweb-box text-center">
          <draggable
            class="d-flex flex-wrap justify-center draggable-box-onweb"
            :list="products.onWeb"
            style="margin: auto; width: fit-content;"
            group="products">
            <product-card
              v-for="(product, index) in products.onWeb"
              :key="`products-rest-${index}`"
              class="pa-1 cursor-pointer"
              :product="product" />
          </draggable>
          <a
            v-if="disabledLoadMoreOnWebBtn"
            class="my-2 text-decoration-underline"
            @click="loadMoreProducts('onWeb')">
            Load More
          </a>
        </div>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col cols="12">
            <h3>พักไว้ก่อน</h3>
            <div class="products-rest-box ">
              <draggable
                class="d-flex flex-wrap justify-center draggable-box"
                :list="products.rest"
                group="products">
                <product-card
                  v-for="(product, index) in products.rest"
                  :key="`products-${index}`"
                  :img-width="'80px'"
                  :img-height="'120px'"
                  class="pa-1"
                  :product="product" />
              </draggable>
            </div>
          </v-col>

          <v-col cols="12">
            <h3>ซ่อนจากเว็บ</h3>
            <search-by-tags
              v-model="searchByTagsValue"
              append-icon="mdi-magnify"
              :tags="tags"
              @on-search="searchHideOnweb()" />
          </v-col>
          <v-col cols="12">
            <div class="products-offweb-box text-center">
              <draggable
                class="d-flex flex-wrap justify-center draggable-box"
                :list="products.hideFromWeb"
                group="products">
                <product-card
                  v-for="(product, index) in products.hideFromWeb"
                  :key="`products-hide-${index}`"
                  :img-width="'80px'"
                  :img-height="'120px'"
                  class="pa-1"
                  :product="product" />
              </draggable>
              <a
                v-if="products.hideFromWeb.length > 0"
                class="my-2"
                :disabled="disabledLoadMoreHideFromWebBtn"
                @click="loadMoreProducts('hideFromWeb')">
                More...
              </a>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <sorting-draft-form
      v-if="isShowDraftForm"
      v-model="formData"
      @on-submit="draft()"
      @on-close="closeDraft()" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Draggable from 'vuedraggable'
import ProductProvider from '@/resources/ProductProvider'
import SortingDraftProvider from '@/resources/SortingDraft.provider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SearchByTags from '@/components/SearchByTags.vue'
import BrandTabSelect from '@/components/BrandTabSelect.vue'
import ProductCard from '../components/ProductCard.vue'
import SortingDraftForm from '../components/SortingDraftForm.vue'

const ProductService = new ProductProvider()
const ProductAttributeService = new ProductAttributeProvider()
const SortingDraftService = new SortingDraftProvider()

export default {
  components: {
    Draggable,
    ProductCard,
    SearchByTags,
    SortingDraftForm,
    BrandTabSelect
  },
  data () {
    return {
      searchByTagsValue: {
        search: '',
        tags: [],
        tagOperation: 'OR'
      },
      brandSelect: '',
      isShowDraftForm: false,
      selectedDraft: {
        id: null,
        name: 'Create sorting draft',
        productsMemo: [],
        hideFormWeb: [],
        onWeb: [],
        rest: []
      },
      formData: {
        id: null,
        name: '',
        productsMemo: [],
        hideFormWeb: [],
        onWeb: [],
        rest: []
      },
      sortingDraft: [],
      tags: [],
      products: {
        onWeb: [],
        hideFromWeb: [],
        rest: []
      },
      productsMemo: [],
      paginate: {
        onWeb: {
          page: 1,
          lastPage: 2,
          itemsPerPage: 50,
          sortDesc: [false],
          sortBy: ['priority'],
          isOnWeb: true
        },
        hideFromWeb: {
          page: 1,
          lastPage: 2,
          itemsPerPage: 30,
          search: '',
          sortDesc: [false],
          sortBy: ['updatedAt'],
          isOnWeb: false
        }
      }
    }
  },
  computed: {
    disabledSaveBtn () {
      return this.products.rest.length > 0
    },
    disabledLoadMoreOnWebBtn () {
      if (this.products.onWeb.length === 0) {
        return false
      }
      return this.paginate.onWeb.lastPage > this.paginate.onWeb.page
    },
    disabledLoadMoreHideFromWebBtn () {
      return this.paginate.hideFromWeb.lastPage <= this.paginate.hideFromWeb.page
    }
  },
  watch: {
    brandSelect () {
      this.reFetch()
    },
    async selectedDraft () {
      if (!this.selectedDraft.id) {
        this.initProduct()
        return
      }

      const sortingDraft = await this.getSortingDraft(this.selectedDraft?.id || null)
      await this.fetchProducts('onWeb')

      this.productsMemo = this.toUniqueItems([...sortingDraft.productsMemo, ...this.productsMemo])
      this.products.hideFromWeb = this.toUniqueItems([...sortingDraft.hideFormWeb, ...this.products.hideFromWeb])
      this.products.onWeb = this.toUniqueItems([...sortingDraft.onWeb, ...this.products.onWeb])
      this.products.rest = this.toUniqueItems([...sortingDraft.rest, ...this.products.rest])
      this.formData = {
        ...sortingDraft,
        productsMemo: this.productsMemo,
        hideFormWeb: this.products.hideFromWeb,
        onWeb: this.products.onWeb,
        rest: this.products.rest
      }
    }
  },
  async mounted () {
    // TODO: ตอน Load sorting draft ให้เอา Product page 1 มาต่อท้ายด้วย แล้ว Compare กัน
    await this.getTags()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    async initSortingDraft () {
      if (this.brandSelect === '') return
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GET SORTING DRAFT...'
        })
        this.sortingDraft = [
          {
            id: null,
            name: 'Create sorting draft',
            productsMemo: this.productsMemo,
            hideFormWeb: this.products.hideFromWeb,
            onWeb: this.products.onWeb,
            rest: this.products.rest
          }
        ]
        const { data } = await SortingDraftService.getSortingDraft({
          brand: this.brandSelect
        })

        if (data) {
          this.sortingDraft.push(...data)
        }
      } catch (error) {
        console.error('initSortingDraft', error)
        this.setSnackbar({
          value: true,
          message: `[INIT-SORTING-DRAFT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async getSortingDraft (id) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GET SORTING DRAFT...'
        })

        const { data } = await SortingDraftService.getSortingDraftById(id)

        return data
      } catch (error) {
        console.error('getSortingDraft', error)
        this.setSnackbar({
          value: true,
          message: `[INIT-SORTING-DRAFT-ERROR]: ${error.message}`,
          type: 'error'
        })
        return null
      } finally {
        this.setLoading({ active: false })
      }
    },
    showDraft () {
      this.formData = { ...this.selectedDraft }
      this.isShowDraftForm = true
    },
    closeDraft () {
      this.isShowDraftForm = false
    },
    confirmDeleteDialog () {
      this.setModal({
        value: true,
        title: 'Remove Sorting Draft',
        message: 'Do you want to remove the sorting draft',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.deleteDraft()
      })
    },
    async deleteDraft () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'DELETE DRAFT...'
        })

        if (this.selectedDraft.id) {
          await SortingDraftService.deleteSortingDraft(this.selectedDraft.id)

          this.setSnackbar({
            value: true,
            message: 'Delete draft success.',
            type: 'success'
          })
        }

        this.isShowDraftForm = false
        this.fetchProducts('onWeb', true)
      } catch (error) {
        console.error('draft', error)
        this.setSnackbar({
          value: true,
          message: `[DRAFT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async draft () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATING DRAFT...'
        })

        this.formData.hideFormWeb = this.products.hideFromWeb.map((p) => ({
          id: p.id,
          name: p.name,
          model: p.model,
          photoUrls: p.photoUrls,
          priority: p.priority,
          isOnWeb: p.isOnWeb
        }))

        this.formData.onWeb = this.products.onWeb.map((p) => ({
          id: p.id,
          name: p.name,
          model: p.model,
          photoUrls: p.photoUrls,
          priority: p.priority,
          isOnWeb: p.isOnWeb
        }))

        this.formData.rest = this.products.rest.map((p) => ({
          id: p.id,
          name: p.name,
          model: p.model,
          photoUrls: p.photoUrls,
          priority: p.priority,
          isOnWeb: p.isOnWeb
        }))

        this.formData.productsMemo = this.productsMemo.map((p) => ({
          id: p.id,
          name: p.name,
          model: p.model,
          photoUrls: p.photoUrls,
          priority: p.priority,
          isOnWeb: p.isOnWeb
        }))

        if (this.formData.id) {
          await SortingDraftService.updateSortingDraft({
            ...this.formData,
            brand: this.brandSelect
          })

          this.setSnackbar({
            value: true,
            message: 'Update draft success.',
            type: 'success'
          })
        } else if (this.isShowDraftForm) {
          await SortingDraftService.createSortingDraft({
            ...this.formData,
            brand: this.brandSelect
          })

          this.setSnackbar({
            value: true,
            message: 'Create draft success.',
            type: 'success'
          })
        }

        this.isShowDraftForm = false
        this.reFetch()
      } catch (error) {
        console.error('draft', error)
        this.setSnackbar({
          value: true,
          message: `[DRAFT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async save () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATING PRIORITY...'
        })

        const onWeb = this.mapForUpdatePriority()
        const hideFromWeb = this.findWhichToHideOnWeb()
        // const newOnWeb = this.findNewOnWeb()
        // const diff = newOnWeb.length - hideFromWeb.length

        await this.updatePriorityAndIsOnWeb({
          products: onWeb,
          updatePriority: true,
          brand: this.brandSelect
        })

        await this.updatePriorityAndIsOnWeb({
          products: hideFromWeb,
          updatePriority: false,
          brand: this.brandSelect
        })

        if (this.selectedDraft.id) {
          await SortingDraftService.deleteSortingDraft(this.selectedDraft.id)
        }

        this.fetchProducts('onWeb', true)
        this.initSortingDraft()
      } catch (error) {
        console.error('save', error)
        this.setSnackbar({
          value: true,
          message: `[SAVE-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    findNewOnWeb () {
      const results = []
      for (const product of this.products.onWeb) {
        const found = this.productsMemo.findIndex((each) => each.id === product.id)
        if (found === -1) {
          results.push({
            id: product.id,
            isOnWeb: false
          })
        }
      }

      return results
    },
    findWhichToHideOnWeb () {
      const results = []
      for (const product of this.productsMemo) {
        const found = this.products.onWeb.findIndex((each) => each.id === product.id)
        if (found === -1) {
          results.push({
            id: product.id,
            isOnWeb: false
          })
        }
      }

      return results
    },
    async updatePriorityAndIsOnWeb (payloads) {
      try {
        await ProductService.updatePriorityAndIsOnWeb(payloads)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[UPDATING-PRODUCT-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    mapForUpdatePriority () {
      return this.products.onWeb.map((product, index) => ({
        priority: index,
        id: product.id,
        isOnWeb: true
      }))
    },
    loadMoreProducts (state) {
      const { page, lastPage } = this.paginate[state]

      if (page < lastPage) {
        this.paginate[state] = {
          ...this.paginate[state],
          tags: this.searchByTagsValue.tags,
          tagOperation: this.searchByTagsValue.tagOperation,
          search: this.searchByTagsValue.search,
          page: this.paginate[state].page + 1
        }

        this.fetchProducts(state)
      }
    },
    resetProduct () {
      this.selectedDraft = {
        id: null,
        name: 'Create sorting draft',
        productsMemo: [],
        hideFormWeb: [],
        onWeb: [],
        rest: []
      }
      this.products = {
        onWeb: [],
        hideFromWeb: [],
        rest: []
      }
      this.productsMemo = []
      this.paginate = {
        onWeb: {
          page: 1,
          lastPage: 2,
          itemsPerPage: 50,
          sortDesc: [false],
          sortBy: ['priority'],
          isOnWeb: true
        },
        hideFromWeb: {
          page: 1,
          lastPage: 2,
          itemsPerPage: 30,
          search: '',
          sortDesc: [false],
          sortBy: ['updatedAt'],
          isOnWeb: false
        }
      }
    },
    async initProduct () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING PRODUCT...'
        })
        this.products = {
          onWeb: [],
          hideFromWeb: [],
          rest: []
        }
        this.paginate = {
          onWeb: {
            page: 1,
            lastPage: 2,
            itemsPerPage: 50,
            sortDesc: [false],
            sortBy: ['priority'],
            isOnWeb: true
          },
          hideFromWeb: {
            page: 1,
            lastPage: 2,
            itemsPerPage: 30,
            search: '',
            sortDesc: [false],
            sortBy: ['updatedAt'],
            isOnWeb: false
          }
        }
        const { data } = await ProductService.getProductsForSorting({
          ...this.paginate.onWeb,
          brand: this.brandSelect
        })
        this.paginate.onWeblastPage = data.pages
        this.products.onWeb = data.results
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-PRODUCT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async fetchProducts (state, reset = false) {
      if (this.brandSelect === '') return

      if (reset) this.resetProduct()

      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING PRODUCT...'
        })

        const { data } = await ProductService.getProductsForSorting({
          ...this.paginate[state],
          brand: this.brandSelect
        })

        this.paginate[state].lastPage = data.pages

        if (reset) {
          this.products[state] = data.results

          if (state === 'onWeb') {
            this.productsMemo = data.results
          }
        } else {
          // this.products[state].push(...data.results)
          this.products[state] = this.toUniqueItems([...this.products[state], ...data.results])

          if (state === 'onWeb') {
            // this.productsMemo.push(...data.results)
            this.productsMemo = this.toUniqueItems([...this.productsMemo, ...data.results])
          }
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-PRODUCT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async searchHideOnweb () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING PRODUCT...'
        })

        this.paginate.hideFromWeb.search = this.searchByTagsValue.search

        const { data } = await ProductService.getProductsForSorting({
          ...this.paginate.hideFromWeb,
          page: 1,
          brand: this.brandSelect,
          lastPage: 2,
          tags: this.searchByTagsValue.tags,
          tagOperation: this.searchByTagsValue.tagOperation
        })
        this.paginate.hideFromWeb.lastPage = data.pages
        this.products.hideFromWeb = this.removeDupOnWeb(data.results)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-PRODUCT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    removeDupOnWeb (list) {
      const result = []
      for (const each of list) {
        const dup = this.products.onWeb.findIndex((onWeb) => onWeb.id === each.id) !== -1

        if (!dup) {
          result.push(each)
        }
      }

      return result
    },
    async getTags () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setSnackbar({
          value: true,
          message: `[GET-TAGS-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async reFetch () {
      await this.initSortingDraft()
      this.fetchProducts('onWeb', true)
    },
    toUniqueItems (items) {
      return items.reduce((arr, item) => {
          if (arr.some((i) => i.id === item.id)) {
            return arr
          }

          return [...arr, item]
        }, [])
    }
  }
}
</script>

<style scoped>
.products-onweb-box {
  height: calc(100vh - 300px);
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 6px;
  overflow-y: auto;
}
.products-rest-box {
  height: calc(50vh - 300px);
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 6px;
  overflow-y: auto;
}
.products-offweb-box {
  height: calc(50vh - 110px);
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 6px;
  overflow-y: auto;
}
.draggable-box {
  min-height: 87%;
}

.draggable-box-onweb {
  min-height: 33%;
}
.load-more-icon-box {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.search-tags {
  width: 100%;
}
.search-product-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid darkgray;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
