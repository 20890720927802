<template>
  <div class="tab-list">
    <div
      v-for="(item, index) of allBrands"
      :key="`tab-${index}`"
      class="tab-item"
      :class="{ 'tab-active': item === brandSelect }"
      @click="brandSelect = item">
      {{ item }}
    </div>
  </div>
</template>

<script>
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'

const ProductAttributeService = new ProductAttributeProvider()

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      allBrands: []
    }
  },
  computed: {
    brandSelect: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  async mounted () {
    await this.getBrands()
    if (this.allBrands.length > 0) {
      this.brandSelect = this.allBrands[0]
    } else {
      this.brandSelect = 'all'
    }
  },
  methods: {
    async getBrands () {
      try {
        this.loading = true
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 9999,
          sortBy: 'createdAt',
          sortOrder: 'asc'
        })

        this.allBrands = [...data.results.map((re) => re.name)]
      } catch (error) {
        console.error('getAttribute: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    commitSearch () {
      this.$emit('on-select', this.brandSelect)
    }
  }
}
</script>

<style scoped>
.tab-list {
  display: flex;
  width: 100%;
  background-color: #fff;
}
.tab-item {
  padding: 20px;
  cursor: pointer;
  transition: all .3s;
}
.tab-active {
  color: #fff;
  background-color: #cfcfcf;
}
</style>
